<template>
  <div class="code-guide__container">
    <h2 class="code-guide__title" @click="showCanUse = !showCanUse">
      {{ $route.meta.name }}
      <i class="icon-down" :class="{ rotate: showCanUse }"></i>
    </h2>
    <div
      class="code-guide__details-content"
      :class="{ 'code-guide__details-content_close': !showCanUse }"
    >
      <p class="code-guide__content">
        Для использования данной страницы необходимо зайти в компонент
        <b>AopGuide ('@/modules/UiKit/components/AopGuide/AopGuide.vue')</b>.
      </p>
      <p class="code-guide__content">
        В компоненте <b>AopGuide</b> в разделе <b>data</b> в переменную <b>code</b> необходимо
        поместить нужную для отрисовки разметку.
      </p>
      <img
        class="code-guide__img"
        src="~@/assets/img/UiKit/aop-code-data.png"
        alt="aop-code"
        width="1000"
        height="auto"
      />
      <p class="code-guide__content">
        Не забудь те импортировать нужный вам компонет, а так же добавить его в исклчениея для
        <b>ESLint</b>.
      </p>
      <img
        class="code-guide__img"
        src="~@/assets/img/UiKit/aop-code-import-components.png"
        alt="aop-code"
        width="1000"
        height="auto"
      />
      <p class="code-guide__content">
        После этого и разметка и сам компонент отразятся на странице компонетов в гайде в разделе
        <b>Как использовать</b>. Где вы в режиме реального времени можете поменять настройки
        компонента и увидеть все изменения.
      </p>
      <img
        class="code-guide__img"
        src="~@/assets/img/UiKit/aop-code-codegeneration.png"
        alt="aop-code"
        width="1000"
        height="auto"
      />
      <p class="code-guide__content">
        Для удобства можно вставлять полные разметки станицы нужного нам элемента Ui Kit и меняя
        различные настройки отслеживать изменения.
      </p>
      <img
        class="code-guide__img"
        src="~@/assets/img/UiKit/aop-code-pagegeneration.png"
        alt="aop-code"
        width="1000"
        height="auto"
      />
      <p class="code-guide__content">
        Важно учитывать, что в данной разметке отображаются стили только из импортированных
        компонентов. Внешние стили остаются стандартными - зависят от браузера. Чтобы этого избежать
        нужно прописать все нужные стили в самом файле <b>AopGuide</b> в разделе <b>style</b>. Для
        того, чтобы они срабоали для разметки необходимо перед классами нужных стилей добавить
        <b>/deep/</b>.
      </p>
      <img
        class="code-guide__img"
        src="~@/assets/img/UiKit/aop-code-style.png"
        alt="aop-code"
        width="1000"
        height="auto"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AopGuideInstruction",
  mixins: [],
  props: [],
  components: {},
  data() {
    return {
      showCanUse: false
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.code-guide {
  .vue-codemirror {
    margin: 32px 0 0;
  }

  &__title {
    width: 100%;
    margin: 0;
    padding: 0 0 24px;
    color: $text-default;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid $border-disabled;

    .icon-down {
      transition: all 0.5s ease-in-out;
    }

    .rotate {
      transform: rotate(180deg);
    }
  }

  &__content {
    color: $text-default;
    font-size: 16px;
    line-height: 20px;
    margin: 24px 0;
    max-width: 1000px;
  }

  &__img {
    display: block;
    margin: 0 0 32px;
  }
  &__details-content {
    animation-name: open;
    animation-timing-function: ease-in-out;
    animation-duration: 0.5s;
    transform: scale(1, 1);
    transform-origin: center top;
    display: block;
    max-height: 10000px;
  }

  &__details-content_close {
    animation-name: close;
    animation-timing-function: ease-in-out;
    animation-duration: 0.5s;
    transform: scale(1, 0);
    transform-origin: center top;
    animation-fill-mode: both;
    max-height: 0;
  }

  @keyframes open {
    from {
      transform: scale(1, 0);
      max-height: 0;
    }
    to {
      transform: scale(1, 1);
      max-height: 10000px;
    }
  }

  @keyframes close {
    from {
      transform: scale(1, 1);
      max-height: 10000px;
    }
    to {
      transform: scale(1, 0);
      max-height: 0;
    }
  }
}
</style>
