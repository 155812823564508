<template>
  <section class="code-guide">
    <AopGuideInstruction />
    <div class="code-guide__container">
      <h2 class="code-guide__title" @click="showComponentsGeneration = !showComponentsGeneration">
        Генерация компонентов
        <i class="icon-down" :class="{ rotate: showComponentsGeneration }"></i>
      </h2>
      <div
        class="code-guide__details-content"
        :class="{ 'code-guide__details-content_close': !showComponentsGeneration }"
      >
        <v-run-time-template :template="code"></v-run-time-template>
        <aop-code v-model="code" />
      </div>
    </div>
    <div class="code-guide__container">
      <h2 class="code-guide__title" @click="showExamples = !showExamples">
        Примеры генерации различных компонентов
        <i class="icon-down" :class="{ rotate: showExamples }"></i>
      </h2>
      <div
        class="code-guide__details-content"
        :class="{ 'code-guide__details-content_close': !showExamples }"
      >
        <p class="code-guide__content">
          тут будут скрины с различными сгенерированными компонентами
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import AopCode from "@/modules/UiKit/components/AopCode";
import VRunTimeTemplate from "v-runtime-template";
import AopGuideInstruction from "@/modules/UiKit/pages/ComponentsPage/Components/AopGuide/AopGuideInstruction";
export default {
  name: "AopGuide",
  components: {
    AopGuideInstruction,
    AopCode,
    VRunTimeTemplate
  },
  data() {
    return {
      code: ``,
      showComponentsGeneration: false,
      showExamples: false
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.code-guide {
  .vue-codemirror {
    margin: 32px 0;
  }

  &__title {
    width: 100%;
    margin: 0;
    padding: 0 0 24px;
    color: $text-default;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid $border-disabled;

    .icon-down {
      transition: all 0.5s ease-in-out;
    }

    .rotate {
      transform: rotate(180deg);
    }
  }

  &__content {
    color: $text-default;
    font-size: 16px;
    line-height: 20px;
    margin: 24px 0;
    max-width: 1000px;
  }

  &__img {
    display: block;
    margin: 0 0 32px;
  }
  &__details-content {
    animation-name: open;
    animation-timing-function: ease-in-out;
    animation-duration: 0.5s;
    transform: scale(1, 1);
    transform-origin: center top;
    display: block;
    max-height: 10000px;
  }

  &__details-content_close {
    animation-name: close;
    animation-timing-function: ease-in-out;
    animation-duration: 0.5s;
    transform: scale(1, 0);
    transform-origin: center top;
    animation-fill-mode: both;
    max-height: 0;
  }

  @keyframes open {
    from {
      transform: scale(1, 0);
      max-height: 0;
    }
    to {
      transform: scale(1, 1);
      max-height: 10000px;
    }
  }

  @keyframes close {
    from {
      transform: scale(1, 1);
      max-height: 10000px;
    }
    to {
      transform: scale(1, 0);
      max-height: 0;
    }
  }
}
</style>
