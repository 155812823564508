<template>
  <codemirror :value="value" :options="options" @input="input($event)"></codemirror>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";

export default {
  name: "AopCode",
  components: { codemirror },
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      options: {
        tabSize: 2,
        mode: "text/javascript",
        theme: "monokai",
        lineNumbers: true,
        line: true
      }
    };
  },
  methods: {
    input(event) {
      this.$emit("input", event);
    }
  }
};
</script>

<style scoped lang="scss"></style>
